
//  components
import Sosials from '../components/Socials';
import { Link } from 'react-router-dom';




const Header = () => {
  return (
    <header className="absolute z-30 w-full flex items-center px-16 xl:px-0 xl:h-[90px]" >
      <div className="container mx-auto">
        <div className="header-css flex flex-col lg:flex-row justify-between items-center gap-y-6 py-5">
       {/* logo */}
       <Link to='/'>
       
        <h1 className="logo text-4xl text-center">Burxan <span className='h1 text-4xl'>Hasanli</span><span className=" text-accent">.</span></h1>
       </Link>
       {/* sosial */}
       <Sosials/>
        </div>
        </div>
    </header>
  )
};

export default Header;
