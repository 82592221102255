import {Particles} from 'react-tsparticles';
import {loadFull} from 'tsparticles';
import React,{useCallback} from 'react';


const ParticlesContainer = () => {
const particlesInit = useCallback(async (engine)=>{
  await loadFull(engine)
}, []);

const particlesLoaded = useCallback (async()=>{},[]);

  return (
    <Particles className='w-full h-full absolute translate-z-0'
   id='tsparticles' 
   init={particlesInit}
    loaded={particlesLoaded}
  options={{
    fullScreen:{enable:false},
    background:{
      color:{
        value: '',
      },
    },
    fpsLimit:120,
    interactivity:{
      events:{
        onClick:{
          enable:false,
          mode:'push',
        },
        onHover:{
          enable:true,
          mode:'repulse',
        },
        resize:true,
      },
      modes:{
        push:{
          quantity:90
        },
        repulse:{
          distance:200,
          duration: 0.4,
        },
      },
    },
   
    particles: {
      number: {
        value: 194,
        density: {
          enable: false,
          value_area: 1104.3796541713996
        }
      },
      color: {
        value: "#ffffff"
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#000000"
        },
        polygon: {
          nb_sides: 5
        },
        image: {
          src: "http://wiki.lexisnexis.com/academic/images/f/fb/Itunes_podcast_icon_300.jpg",
          width: 100,
          height: 100
        }
      },
      opacity: {
        value: 0.4496402877697841,
        random: false,
        anim: {
          enable: false,
          speed: 1.21580547112462,
          opacity_min: 0.0972644376899696,
          sync: false
        }
      },
      size: {
        value: 5,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false
        }
      },
      line_linked: {
        enable: false,
        distance: 192.0691448921613,
        color: "#ffffff",
        opacity: 0.4,
        width: 1.4405185866912096
      },
      move: {
        enable: true,
        speed: 4,
        direction: "top",
        random: true,
        straight: true,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 640.230482973871,
          rotateY: 2560.921931895484
        }
      }
    },
    
    Iinteractivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        },
        onclick: {
          enable: true,
          mode: "push"
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 200,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3
        },
        repulse: {
          distance: 200,
          duration: 0.4
        },
        push: {
          particles_nb: 4
        },
        remove: {
          particles_nb: 2
        }
      }
    },
    retina_detect: true
  }
  }
  />
  )
};

export default ParticlesContainer;
