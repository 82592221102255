import { BsArrowRight } from "react-icons/bs"
import { motion } from "framer-motion"
import { fadeIn } from '../../src/variants'
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';


const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });


  const formref = useRef()

  const sendemail = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      alert("Lütfen tüm alanları doldurun.");
      return;
    }

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });

    const serviceid = "service_kxro5we"
    const templateid = "template_jsu4qbf"
    const PUBLIC_KEY ="nJUZl_9UE7nxMClDi"


    emailjs.sendForm(serviceid, templateid, formref.current, PUBLIC_KEY)
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
  }
 
  




  return <motion.div
  initial="initialState"
     animate="animateState"
     exit="exitState"
     transition={{
       duration: 0.75,
     }}
     variants={{
       initialState: {
         opacity: 0,
         clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
       },
       animateState: {
         opacity: 1,
         clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
       },
       exitState: {
         clipPath: "polygon(50% 0, 50% 0, 50% 100%, 50% 100%)",
       },
     }} 

   className="contact h-full bg-primary/30  ">
    <div className="container mx-auto py-32 text-center xl:text-left flex items-center justify-center h-full">
      <motion.div 
      variants={fadeIn('up',0.2)} initial="hidden" animate='show'
      exit='hidden'
      className="flex flex-col w-full max-w-[700px]">
        <h2 className="contact-h2 h2 text-center mb-4 md:mb-12">Let's <span className="text-accent">connect.</span></h2>
        <form 
         action="" ref={formref} onSubmit={sendemail} 
        className="flex-1 flex flex-col gap-6 w-full mx-auto" >
          <div className="contact-form flex gap-x-6 w-full">
            <input 
            type="text" 
            name="name" 
            placeholder="name" 
            className="contact-form input"
            value={formData.name}
            required
             onChange={(e) => setFormData({ ...formData, name: e.target.value })}  />
            <input 
            type="text"
            name="email" 
            placeholder="email"
            value={formData.email} 
            className="contact-form input" onChange={(e) => setFormData({ ...formData, email: e.target.value })} required 
            />
          </div>
          <input type="text" 
          name="subject" 
          placeholder="subject" 
          className="contact-form input"
          value={formData.subject} 
          required
           onChange={(e) => setFormData({ ...formData, subject: e.target.value })}/>
          <textarea
           placeholder="message"
           value={formData.message}
           name="message"
           className="contact-textarea textarea"
           required
           onChange={(e) => setFormData({ ...formData, message: e.target.value })} ></textarea>
          <button 
          
          type="submit"
          className="contact-btn btn rounded-full border border-white/50 max-w-[170px]
            px-8 transition-all duration-300 flex items-center justify-center overflow-hidden hover:border-accent group">
            <span className="group-hover:translate-y-[120%] group-hover:opacity-0 transition-all duration-500">Let's talk</span>
            <BsArrowRight
             className="-translate-y-[120%] opacity-0 group-hover:flex
              group-hover:-translate-y-0 group-hover:opacity-100 transition-all
              duration-300 absolute text-[22px]"/>
          </button>

        </form>
      </motion.div>
    </div>
  </motion.div>
};

export default Contact;

