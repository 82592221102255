import React, { useState } from "react";
import {
  FaHtml5,
  FaCss3,
  FaJs,
  FaReact,
  FaNodeJs,
  FaBootstrap,
  FaFigma,
 
} from "react-icons/fa";
import { TbBrandReactNative} from 'react-icons/tb'

import {
  SiNextdotjs,
  SiTailwindcss,
  SiMongodb,
  SiExpress,
  SiAntdesign,
  SiRedux,
  SiJson,
} from "react-icons/si";




import Avatar from "../../src/components/Avatar"


import { motion } from "framer-motion"
import { fadeIn } from "../../src/variants"

import CountUp from "react-countup";


const aboutData = [
  {
    id:1,
    title: 'skills',
    info: [
      {
        title: 'MERN Stack Development',
        icons: [
          <SiMongodb key={"mongo"}/>,
          <SiExpress key={"express"}/>,
          <FaReact key={"react"}/>,
          <FaNodeJs key={"node"}/>,
          <SiNextdotjs key={"next"}/>,
          <SiRedux key={"redux"}/>,
          <FaHtml5 key={"html"} />,
          <FaCss3 key={"css"} />,
          <FaJs key={"js"}/>,
          <SiTailwindcss key={"tailwind"} />,
          <SiAntdesign key={"antdesign"}/>,

        ],
      },
      {
        title: 'Front End Development',
        icons: [
          <FaHtml5 key={"html5"} />,
          <FaCss3 key={"css3"} />,
          <FaJs key={"javascript"} />,
          <FaReact key={"reactt"}/>,
          <TbBrandReactNative key={"reactNative"}/>,
          <SiJson key={"json"}/>,
          <FaBootstrap key={"bootstarp"}/>,
          <FaFigma key={"figma"}/>
        ],
      }
    ],
  },
  {
    id:2,
    title: 'awards',
    info: [
      {
        title: 'Golden Degree Of Diploma In Front End Development - IT Brains Academy',
      },
      {
        title: 'MERN Stack Development - Udemy',
      },
      {
        title: 'International Certification Of JavaScript Institute - JSE',
      },
      {
        title: 'React-Native Udemy',
      },
      {
        title: 'CISCO - Get Connected',
      },
      {
        title: 'Pyhton Programming - Udemy',
      },
     
    ],
  },
  {
    id:3,
    title: 'experience',
    info: [
      {
        title: 'Front End Developer - JED Academy',
        stage: '2022 - 2023',
      },
      {
        title: 'MERN Stack Developer',
        stage: '2023 - Now',
      },
    ],
  },

];

const About = () => {
  const [index, setIndex] = useState(0)
  return <motion.div
  initial="initialState"
  animate="animateState"
  exit="exitState"
  transition={{
    duration: 0.75,
  }}
  variants={{
    initialState: {
      opacity: 0,
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    },
    animateState: {
      opacity: 1,
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    },
    exitState: {
      clipPath: "polygon(50% 0, 50% 0, 50% 100%, 50% 100%)",
    },
  }} 
   className="h-full bg-primary/30 py-32 text-center xl:text-left">
 
    <motion.div
      variants={fadeIn('right', 0.2)}
      initial="hidden"
      animate="show"
      exit='hidden'
      className="hidden xl:flex absolute bottom-0 -left-[0px] w-[420px]">
      <Avatar />
    </motion.div>

    <div className="about container mx-auto h-full flex flex-col items-center xl:flex-row gap-x-6 ">

      <div className="flex-1 flex flex-col justify-center px-0 md:px-8">
        <motion.h2
          variants={fadeIn('right', 0.2)}
          initial="hidden"
          animate="show"
          exit='hidden'
          className="about-right-up text-xl font-bold">Focusing on WHY instead of WHAT in your code makes you a better <span className="text-accent">developer</span></motion.h2>
        <motion.p
          variants={fadeIn('right', 0.4)}
          initial="hidden"
          animate="show"
          exit='hidden'
          className="about-right-down max-w-[500px] mx-auto xl:mx-0 my-2  xl:mb-12 px-2 xl:px-0 text-sm">
            I started coding as a frontend developer. My biggest
hobby is writing code. This love of coding led me to
Mern stack and Mobile developer. The projects I
work on I have learned to work alone and as a team.
I strive to be the best in the projects I work on
because it will be the signature of <span className="text-accent">Burxan Hasanli.</span>
        </motion.p>

        <motion.div
          variants={fadeIn('right', 0.6)}
          initial="hidden"
          animate="show"
          exit='hidden'
          className="hidden md:flex md:max-w-xl xl:max-w-none mx-auto xl:mx-0
          mb-8">
          <div className="flex flex-1 xl:gap-x-6">
            <div className="relative flex flex-col items-center md:items-start flex-1 after:w-[1px] after:h-full
              after:bg-white/10 after:absolute after:top-0 after:right-0">
              <div className="text-2xl xl:text-4xl font-extrabold text-accent mb-2">
                <CountUp start={0} end={2} duration={5} /> +
              </div>
              <div className="text-[10px] md:text-xs uppercase tracking-[1px] leading-[1.4] max-w-[100px] ">
                Years of Experience</div>
            </div>

            <div className="relative flex items-center md:items-start flex-col flex-1 after:w-[1px] after:h-full
              after:bg-white/10 after:absolute after:top-0 after:right-0">
              <div className="text-2xl xl:text-4xl font-extrabold text-accent mb-2">
                <CountUp start={0} end={17} duration={5} /> +
              </div>
              <div className="text-[10px] md:text-xs uppercase tracking-[1px] leading-[1.4] max-w-[100px]">
                Finished Projects
              </div>
            </div>

            <div className="relative flex items-center md:items-start flex-col flex-1 after:w-[1px] after:h-full
              after:bg-white/10 after:absolute after:top-0 after:right-0">
              <div className="text-2xl xl:text-4xl font-extrabold text-accent mb-2">
                <CountUp start={0} end={10} duration={5} /> +
              </div>
              <div className="text-[10px] md:text-xs uppercase tracking-[1px] leading-[1.4] max-w-[100px]">
                Awards & Certifications
              </div>
            </div>

          </div>
        </motion.div>

        <div>

        </div>
      </div>

      <motion.div
      variants={fadeIn('left', 0.4)}
      initial="hidden"
      animate="show"
      exit='hidden' 
      className="about-left flex flex-col w-full xl:max-w-[48%] xl:md:mb-0 mb-32">
        <div className=" flex gap-x-4 xl:gap-x-8 mx-auto xl:mx-0 mb-4">
          {
            aboutData.map((item, itemIndex) => {
              return (
                <div key={itemIndex}
                  className= {`${index === itemIndex && 'text-accent  after:w-[100%] after:bg-accent after:transition-all after:duration-300'}
                 cursor-pointer capitalize xl:text-lg relative 
                after:w-8 after:h-[2px] after:bg-white after:absolute after:-bottom-1
                after:left-0`} onClick={() => setIndex(itemIndex)}>
                  {item.title}
                </div>
              )
            })
          }
        </div>

        <div className="py-2 xl:py-6 flex flex-col gap-y-2 
        xl:gap-y-4 items-center xl:items-start">
          {
            aboutData[index].info.map((item, itemIndex) => {
              return (
                <div key={itemIndex} className=" flex-1 flex flex-col md:flex-row max-w-max gap-x-2 items-center text-white/60 ">
                  <div className=" font-light mb-1 md:mb-0 text-[10px] md:text-[16px]">{item.title}</div>
                  <div className="  flex gap-x-4  ">
                    {item.icons?.map((icon, itemIndex) => {
                      return <div key={itemIndex} className="about-left-icon xl:text-2xl    text-xl   text-white benim-ikonla  ">{icon}</div>
                    })}
                  </div>
                  <div>
                    {
                      item.stage ? (
                        <p>{`(${item.stage})`}</p>
                      ) : ""
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </motion.div>
    </div>
  </motion.div>;
};

export default About;
