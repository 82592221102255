
import {BsArrowRight} from 'react-icons/bs'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// data
const workSlides = {
  slides: [
    {
      images: [
        {
          title: 'title',
          path: '/mz.png',
          adress:'https://mzcoffee-burxanhasanli.netlify.app/'
        },
        {
          title: 'title',
          path: '/ps.png',
          adress:'https://posappburxan.onrender.com'
        },
        {
          title: 'title',
          path: '/dc.png',
          adress:'https://documood.netlify.app/'
        },
        {
          title: 'title',
          path: '/bx.png',
          adress:''
        },
       
      ],
    },

  ],
};

const WorkSlider = () => {
  return(
 

<motion.div className="h-[280px] sm:h-[480px]">
  {
      workSlides.slides.map((slide,slideIndex)=>{
      return (
        <div key={slideIndex}>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 cursor-pointer">
            {
              slide.images.map((image, imageIndex) => {
                return (
                
              <Link to={image.adress} key={imageIndex} className="relative rounded-lg overflow-hidden flex items-center justify-center group">
                    <div className="flex items-center justify-center relative overflow-hidden group">
                      {/* image */}
                      <img src={image.path} width={500} height={300} alt="salam" />
                      {/* overlay */}
                      
                      <div className="absolute inset-0 bg-gradient-to-l from-transparent via-[#a10622] to-[#210577] opacity-0 group-hover:opacity-80 transition-all duration-700"></div>
                      {/* title */}
                      <div className="work-hover absolute bottom-0 translate-y-full group-hover:-translate-y-12 group-hover:xl:-translate-y-20 transition-all duration-300">
                        <div className="flex items-center gap-x-2 text-[13px] tracking-[0.2em]">
                          {/* part1 */}
                          <div className="delay-100">LIVE</div>
                          {/* part2 */}
                          <div className="translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-150">PROJECT</div>
                          {/* icon */}
                          
                          <div className="text-xl translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-200">
                            <BsArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </div>
        </div>
      )
    })
  }
</motion.div>

   )
};

export default WorkSlider;
