import { motion } from "framer-motion";
import { fadeIn } from "../../src/variants";
import WorkSlider from "../../src/components/WorkSlider";



const Work = () => {

  return (

    <motion.div 
    initial="initialState"
     animate="animateState"
     exit="exitState"
     transition={{
       duration: 0.75,
     }}
     variants={{
       initialState: {
         opacity: 0,
         clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
       },
       animateState: {
         opacity: 1,
         clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
       },
       exitState: {
         clipPath: "polygon(50% 0, 50% 0, 50% 100%, 50% 100%)",
       },
     }} 

     
     className='work h-full bg-primary/30 py-36 flex items-center'>
      
      <div className="container mx-auto">
      <div className='flex flex-col xl:flex-row gap-x-8'>
      <div className="text-center flex xl:w-[30vw] flex-col lg:text-left mb-4 xl:mb-0 ">
   <motion.h2
    variants={fadeIn('down',0.2)}
    initial='hidden'
    animate='show'
    exit='hidden'
    className="work-h1 h2 xl:mt-12"
   >
   My works <span className="text-accent">.</span>
   </motion.h2>
   <motion.p
    variants={fadeIn('down',0.4)}
    initial='hidden'
    animate='show'
    exit='hidden'
    className="work-p mb-4 max-w-[400px] text-3xl mx-auto lg:mx-0"
   >

    Only some of the projects I deliver to my clients

   </motion.p>
   </div>
   <motion.div
    variants={fadeIn('up',0.4)}
    initial='hidden'
    animate='show'
    exit='hidden'
    className="work-frame"
   >
   <WorkSlider/>
   </motion.div>
   
  </div>

 
     
      </div>
     
    </motion.div>
    
  )
};

export default Work;
