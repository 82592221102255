// icons
import {
  HiHome,
  HiUser,
  HiViewColumns,
  HiEnvelope,
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


// nav data
export const navData = [
  { name: 'home', path: '/', icon: <HiHome /> },
  { name: 'about', path: '/about', icon: <HiUser /> },
  { name: 'work', path: '/work', icon: <HiViewColumns /> },
  
  {
    name: 'contact',
    path: '/contact',
    icon: <HiEnvelope />,
  },
];

//  next link 


// const Nav = () => {

  

//   return(
   
//     <nav className='flex flex-col  items-center xl:justify-center gap-y-4 fixed h-max bottom-0 right-1/2 transform translate-x-1/2 z-50 w-full xl:w-16 xl:max-w-md xl:h-32'>
//   {/* inner */}
//   <div className='flex  w-full xl:flex items-center justify-center gap-x-10 px-4 md:px-40 xl:px-8 h-[80px] xl:w-max py-8 bg-white/10 backdrop-blur-sm text-3xl xl:text-xl xl:rounded-full'>
    
//     {
//       navData.map((Link,index) =>{
//         return <div className='relative flex items-center group hover:text-accent transition-all duration-300' 
       
//         >
//           {/* tooltip */}
         
//           <div className='absolute pr-14 right-0 hidden xl:group-hover:flex'>
//           <div className='bg-white relative flex text-primary items-center p-[6px] rounded-[3px]'>
//           <div className='text-[12px] leading-none font-semibold capitalize'>
//             {Link.name}</div>
//           </div>
//            {/* triangle */}

//            <div className='border-solid border-l-white border-l-8 border-y-transparent border-y-0 absolute -right-2'></div>

//           </div >
          

         



         

//           {/* icon */}
//           <div>{Link.icon}</div>
//           </div>
//       })
//     }
//   </div>
// </nav>

//   )
// };


const Nav = () => {
const location = useLocation();

  return (
    <nav className='flex flex-col items-center xl:justify-center gap-y-4 fixed h-max bottom-0 right-1/2 transform translate-x-1/2 z-50 w-full xl:w-16 xl:max-w-md xl:h-32'>
      {/* inner */}
      <div className='flex w-full xl:flex items-center justify-center gap-x-10 px-4 md:px-40 xl:px-8 h-[80px] xl:w-max py-8 bg-white/10 backdrop-blur-sm text-3xl xl:text-xl xl:rounded-full'>
        {navData.map((item, index) => (
          <div
            key={index}
            className={`relative flex items-center group hover:text-accent transition-all duration-300 ${
              item.path === location.pathname && 'text-accent'}` // active beraber dise index 
            }
          >
            <Link
              to={item.path}
              // her basilginida setin icindeki index islesin
          
            >
              {/* tooltip */}
              {/* ... (aynı kaldı) */}
              {/* icon */}
              <div>{item.icon}</div>
            </Link>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default Nav;





