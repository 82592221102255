import { Link } from 'react-router-dom';

// icons
import {
  RiInstagramLine,
  RiFacebookFill,
  RiLinkedinFill,
  RiGitBranchLine
  
  
} from 'react-icons/ri'

const Socials = () => {
  return (
    <div className="sosial-icon flex items-center gap-x-5 text-xl">


         <Link   to='https://www.instagram.com/burxan.hasanli/'  className="hover:text-accent transition-all duration-300">
        <RiInstagramLine/>
      </Link>


      <Link to='https://www.linkedin.com/in/burxan-hesenli-3b7a67259?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B2JarRTQkRVSC%2Be59OXUfAA%3D%3D' className="hover:text-accent transition-all duration-300">
        <RiLinkedinFill/>
      </Link>

      <Link to='https://www.facebook.com/burxan.hesenli.3' className="hover:text-accent transition-all duration-300">
        <RiFacebookFill/>
      </Link>

      <Link to='https://github.com/Burxan777' className="hover:text-accent transition-all duration-300">
        <RiGitBranchLine/>
      </Link>


    

     

   
    </div>
  )
};

export default Socials;
