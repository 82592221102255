import portfolio from '../img/portfolio.png'

const Avatar = () => {
  return <div className="avatar xl:flex  xl:max-w-[700px] mt-36 ">
    <img src={portfolio}
    width={737}
    height={678}
    alt="avatar"
    className=" avaa translate-z-0 w-full h-full"
    />
  </div>;
};

export default Avatar;
