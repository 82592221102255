import React from 'react'
import Home from './pages/Home';
import Work from './pages/Work';
import Contact from './pages/Contact'
import Aboutt from './pages/Aboutt';


import { Route,Routes,useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './components/Header';

const AnimateRoutes = () => {
    const location = useLocation();
  return (
  
    
    
    
    <AnimatePresence mode='wait'>

<Header/>
    <Routes location={location} key={location.pathname}>
    <Route path="/" element={<Home/>} />
    <Route path="/work" element={<Work/>} />
    <Route path="/about" element={<Aboutt/>} />
    <Route path="/contact" element={<Contact/>}/>
    
    
    
    </Routes>
    </AnimatePresence>
  )
}

export default AnimateRoutes