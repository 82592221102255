
import ParticlesContainer from "../../src/components/ParticlesContainer";
import Avatar from '../../src/components/Avatar'
import { motion } from "framer-motion";
import {fadeIn} from '../../src/variants'


const Home = () => {
 
  return (

   
     <motion.div
     initial="initialState"
     animate="animateState"
     exit="exitState"
     transition={{
       duration: 0.75,
     }}
     variants={{
       initialState: {
         opacity: 0,
         clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
       },
       animateState: {
         opacity: 1,
         clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
       },
       exitState: {
         clipPath: "polygon(50% 0, 50% 0, 50% 100%, 50% 100%)",
       },
     }} 
      className="bg-primary/30 h-full">

  {/* text */}
  <div className="w-full h-full bg-gradient-to-r from-primary/10 via-black/30 to-black/10">
  <div className=" text-center flex flex-col justify-center xl:pt-40 xl:text-right h-full container mx-auto">
    {/* title */}
    <motion.h1
    variants={fadeIn('down',0.3)}
    initial='hidden'
    animate='show'
    exit='hidden'
     className="header-text h1  xl:mt-80">

Creativity Touched <br/> by <span className="text-accent">Codes</span>
      </motion.h1>
      {/* subtotal */}
      <motion.p
      variants={fadeIn('down',0.3)}
      initial='hidden'
      animate='show'
      exit='hidden'
       className="header-p text-xl flex flex-col justify-center  ">
      A good developer is someone who looks <br/>
       both ways when crossing a one-way road.
      </motion.p>
  </div>
  </div>
  {/* image */}
  <div className="avatar-header-div w-full h-full absolute left-0 bottom-0">
    {/* bg img */}
    <div className="bg-none xl:bg-explosion xl:bg-cover xl:bg-right xl:bg-no-repeat
    w-full h-full absolute mix-blend-color-dodge translate-z-0"></div>
    {/* particles */}
    <ParticlesContainer/>
    {/* avatar im */}
    <motion.div
     variants={fadeIn('fade',0.5)}
     initial='hidden'
     animate='show'
     exit='hidden'
     transition={{duration:1, ease: 'easeInOut'}}
     className="avatar-header w-full h-full max-w-[737px] max-h-[678px] absolute -bottom-32
    lg:bottom-0 lg:-[0%]">
      <Avatar/>
    </motion.div>
  </div>
  </motion.div>
  )
};

export default Home;
