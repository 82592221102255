import '../src/styles/globals.css';


// components




// router

import { BrowserRouter } from 'react-router-dom'; 
import Nav from './components/Nav';
import AnimateRoutes from './AnimateRoutes';



function MyApp() {
  // const sora = Sora({
  //   subsets:['latin'],
  //   variable: '--font-sora',
  //   weight: ['100','200','300','400','500','600','700','800']
  // })
  return(
  
  <div className='page bg-site text-white bg-cover bg-no-repeat font-sora relative '>
 

<BrowserRouter>
  
<Nav/>
 
    <AnimateRoutes/>
   
   </BrowserRouter>
    
    </div>
  )
}

export default MyApp;
